import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/link/index',
    name: 'link',
    component: () => import('../views/link/index.vue'),
  },
  // 家长训练营
  {
    path: '/train',
    component: () => import('../layout/xunl.vue'),
    children: [
      {
        path: '/train/index',
        name: 'TrainIndex',
        component: () => import('../views/train/index.vue'),
        meta: { title: '家长训练营' }
      },
      {
        path: '/train/info',
        name: 'TrainInfo',
        component: () => import('../views/train/info.vue'),
        meta: { title: '超常发挥训练营' }
      },
      {
        path: '/train/info2',
        name: 'TrainInfo2',
        component: () => import('../views/train/info2.vue'),
        meta: { title: '亲子家长写作营' }
      },
      {
        path: '/train/info3',
        name: 'TrainInfo3',
        component: () => import('../views/train/info3.vue'),
        meta: { title: '学习力训练营' }
      },
      {
        path: '/train/info4',
        name: 'TrainInfo4',
        component: () => import('../views/train/info4.vue'),
        meta: { title: '生涯规划训练营' }
      },
      {
        path: '/train/consult',
        name: 'Trainconsult',
        component: () => import('../views/train/consult.vue'),
        meta: { title: '专业咨询' }
      },
      {
        path: '/train/agre',
        name: 'agre',
        component: () => import('../views/train/agre.vue'),
        meta: { title: '用户服务协议' }
      },
    ]
  },

  // 父母量表
  {
    path: '/exam',
    component: () => import('../layout/zuot.vue'),
    children: [
      {
        path: '/exam/index',
        name: 'ExamIndex',
        component: () => import('../views/exam/index.vue'),
        meta: { title: '父母教养方式测评' }
      },
      {
        path: '/exam/exam_introduce',
        name: 'ExamIntroduce',
        meta: { title: '父母教养方式测评' },
        component: () => import('../views/exam/exam_introduce.vue'),
      },
      {
        path: '/exam/exam',
        meta: { title: '父母教养方式测评' },
        component: () => import('../views/exam/exam.vue'),
      },
      {
        path: '/exam/report',
        name: 'Report',
        meta: { title: '测评结果' },
        component: () => import('../views/exam/report.vue'),
      },
    ]
  },
  // 睡眠
  {
    path: '/slep',
    component: () => import('../layout/slep.vue'),
    children: [
      {
        path: '/sleep/sleep',
        name: 'SleepIndex',
        component: () => import('../views/sleep/sleep.vue'),
        meta: { title: '儿童睡眠紊乱量表' }
      },
      {
        path: '/sleep/question',
        name: 'Sleepquestion',
        component: () => import('../views/sleep/question.vue'),
        meta: { title: '儿童睡眠紊乱量表' }
      },
      {
        path: '/sleep/result',
        name: 'SleepResult',
        component: () => import('../views/sleep/result.vue'),
        meta: { title: '测评结果' }
      },
    ]
  },
  
]





const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {//beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {//判断是否有标题
    // console.log(to.meta.title, '111');
    document.title = to.meta.title
  }
  next()  //执行进入路由，如果不写就不会进入目标页
})

export default router
